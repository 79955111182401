#root {
  height: 100vh;
  font-family: 'Baloo 2', sans-serif;
}
/********** margin padding function start here **************/
.p-0 {
  padding: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.m-0 {
  margin: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.m-8 {
  margin: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.p-12 {
  padding: 12px;
}
.pt-12 {
  padding-top: 12px;
}
.pl-12 {
  padding-left: 12px;
}
.pr-12 {
  padding-right: 12px;
}
.pb-12 {
  padding-bottom: 12px;
}
.m-12 {
  margin: 12px;
}
.mt-12 {
  margin-top: 12px;
}
.ml-12 {
  margin-left: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
/********** margin padding function end here **************/
.m-hide {
  display: block;
}
@media only screen and (max-width: 767px) {
  .m-hide {
    display: none;
  }
}
.d-hide {
  display: none;
}
@media only screen and (max-width: 767px) {
  .d-hide {
    display: block;
  }
}
.wrapper {
  padding: 10px;
  padding-bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    font-size: 14px;
    padding: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 10px;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 600px) {
  .wrapper {
    padding: 8px;
  }
}
.responsive-table {
  width: 100%;
  overflow: auto;
}
.ant-table .ant-image-mask {
  display: none;
}
.ant-table .filter-icon {
  font-size: 15px;
}
.ant-table .filter-icon.anticon {
  color: #9264a8;
}
.ant-table .filter-filled {
  color: #9264a8;
}
.ant-table .ant-table-tbody .ant-btn {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: auto;
}
.nested-table,
.full-height-without-nav .nested-table,
.mobile-with-search-action-container .nested-table,
.tab-table .nested-table {
  padding: 16px 0px;
}
.nested-table .ant-table-placeholder,
.full-height-without-nav .nested-table .ant-table-placeholder,
.mobile-with-search-action-container .nested-table .ant-table-placeholder,
.tab-table .nested-table .ant-table-placeholder {
  height: auto;
}
.nested-table .ant-table-content,
.full-height-without-nav .nested-table .ant-table-content,
.mobile-with-search-action-container .nested-table .ant-table-content,
.tab-table .nested-table .ant-table-content {
  overflow: visible !important;
}
.nested-table .ant-table-content table,
.full-height-without-nav .nested-table .ant-table-content table,
.mobile-with-search-action-container .nested-table .ant-table-content table,
.tab-table .nested-table .ant-table-content table {
  font-size: 12px;
}
.pointer {
  cursor: pointer;
}
.grab {
  cursor: grab;
}
/********** text alignment start here **************/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
/********** text alignment end here **************/
/********** flex box class start here **************/
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.flex-1 {
  flex: 1;
}
/********** flex box class end here **************/
.width-percent-20 {
  width: 20%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-80 {
  width: 80%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-25 {
  width: 25%;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.full-height-without-nav {
  height: calc(100vh - 85px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.full-height-without-nav .ant-card-body {
  height: calc(100vh - 85px);
  overflow-y: auto;
}
.full-height-without-nav .ant-table-body {
  max-height: none !important;
  height: calc(100vh - 300px);
}
.full-height-without-nav .ant-table-placeholder {
  height: calc(100vh - 310px);
}
@media only screen and (max-width: 600px) {
  .full-height-without-nav {
    height: calc(100vh - 125px);
  }
  .full-height-without-nav .ant-card-body {
    height: calc(100vh - 125px);
    overflow-y: auto;
  }
  .full-height-without-nav .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 351px);
  }
  .full-height-without-nav .ant-table-placeholder {
    height: calc(100vh - 357px);
  }
}
.full-height-with-nav {
  height: calc(100vh - 85px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.full-height-with-nav .ant-card-body {
  height: calc(100vh - 131px);
  overflow-y: auto;
}
@media only screen and (max-width: 600px) {
  .full-height-with-nav {
    height: calc(100vh - 125px);
  }
  .full-height-with-nav .ant-card-body {
    height: calc(100vh - 172px);
  }
}
.full-height-without-details {
  border-radius: 0px;
}
.full-height-without-details .ant-table-body {
  max-height: none !important;
  height: calc(100vh - 390px);
}
.full-height-without-details .ant-table-placeholder {
  height: calc(100vh - 396px);
}
@media only screen and (max-width: 600px) {
  .full-height-without-details .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 455px);
  }
  .full-height-without-details .ant-table-placeholder {
    height: calc(100vh - 461px);
  }
}
.tab-table {
  border-radius: 0px;
}
.tab-table .ant-table-body {
  max-height: none !important;
  height: calc(100vh - 341px);
}
.tab-table .ant-table-placeholder {
  height: calc(100vh - 347px);
}
@media only screen and (max-width: 600px) {
  .tab-table .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 406px);
  }
  .tab-table .ant-table-placeholder {
    height: calc(100vh - 412px);
  }
}
.site-result-demo-error-icon {
  color: #ff4d4f;
}
.b-0 {
  border: none;
}
/*position class start here*/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.sticky {
  position: sticky;
}
/*position class end here*/
.header-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-card-head-title {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-page-header-heading {
    padding: 0;
    min-height: 48px;
  }
}
#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  font-family: PuHuiTi, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji, segoe ui emoji, Segoe UI Symbol, noto color emoji, sans-serif;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #121d45;
}
.delete-icon {
  color: #e92424;
  cursor: pointer;
}
/*table draggable css start here*/
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
/*sidebar custom less start here*/
.responsive-logo img {
  width: 44px;
}
.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0 !important;
  left: 0;
}
.ant-layout-sider.ant-layout-sider-light {
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 80px !important;
  width: 230px !important;
}
.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed {
  width: 80px !important;
}
.ant-layout-sider {
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
@media only screen and (max-width: 767px) {
  .ant-layout-sider {
    width: 0;
    max-width: 0;
    position: fixed !important;
    height: 100%;
    left: -240px;
    z-index: 12;
  }
}
.ant-layout.ant-layout-has-sider {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .ant-layout-header {
    padding: 0 16px !important;
  }
}
header.ant-layout-header.site-layout-sub-header-background.app-header {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 15px;
  color: #ffffff;
  line-height: 64px;
  background: #9264a8;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
@media only screen and (max-width: 767px) {
  .overlay-disable {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.6;
    z-index: 11;
  }
}
/*sidebar custom less end here*/
@media only screen and (max-width: 767px) {
  .ant-layout-footer {
    padding: 16px 50px;
  }
}
/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 7px;
  height: 7px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
  background-color: #aaa;
}
:hover::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}
::-webkit-selection {
  background: #e6dae8 !important;
}
::selection {
  background: #e6dae8 !important;
}
::-moz-selection {
  background: #e6dae8 !important;
}
/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
}
.ant-body-scroll .ant-card-head {
  position: relative;
  z-index: 1;
}
.ant-body-scroll .ant-page-header-heading {
  min-height: 48px;
}
.ant-body-scroll > .ant-card-body {
  flex: 1 1;
  height: 100%;
  padding: 15px;
  overflow: hidden;
}
.ant-body-scroll > .ant-card-body .card-body-wrapper {
  height: 100%;
  overflow: auto;
  padding: 24px;
}
.ant-card-small > .ant-card-body .card-body-wrapper {
  padding: 12px;
}
:where(.css-dev-only-do-not-override-1oigu4d).ant-typography ul li,
:where(.css-dev-only-do-not-override-1oigu4d).ant-typography ol li {
  margin-inline: 0px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 0px;
}
.ant-card .ant-card-head {
  min-height: 50px;
}
/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
}
/*fleg dropdown css end here*/
/*sidebar css start here*/
.sidebar-content {
  overflow-y: auto;
}
.sidebar-content .ant-menu-item-icon {
  background: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(83, 108, 167, 0.16);
  display: flex;
  justify-content: center;
}
.sidebar-content .ant-menu-inline-collapsed span.ant-badge.ant-menu-item-icon {
  margin-left: 5px;
  margin-top: 5px;
}
.sidebar-content .ant-menu-inline-collapsed span.ant-badge.ant-menu-item-icon .anticon {
  margin: 0px;
  background: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(83, 108, 167, 0.16);
  display: flex;
  justify-content: center;
}
.sidebar-content .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-inline: 20px;
}
.sidebar-content li.ant-menu-item.ant-menu-item-only-child {
  padding-left: 55px !important;
}
.sidebar-content .ant-menu-submenu-open {
  background: #f4f5fa;
}
.sidebar-content .ant-menu-inline-collapsed > .ant-menu-item .anticon,
.sidebar-content .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin-left: 5px;
  margin-top: 5px;
}
.sidebar-content .ant-menu-inline-collapsed.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  background: #e6dae8;
}
.sidebar-content .ant-menu-inline .ant-menu-item,
.sidebar-content .ant-menu-vertical .ant-menu-item,
.sidebar-content .ant-menu-inline .ant-menu-submenu-title,
.sidebar-content .ant-menu-vertical .ant-menu-submenu-title {
  width: auto;
  padding-left: 15px !important;
}
.sidebar-content .ant-menu-inline-collapsed .ant-menu-submenu-open {
  background: none;
}
.sidebar-content .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: none;
}
.sidebar-content ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  height: calc(100dvh - 230px);
  padding: 10px 5px;
  border-bottom: 2px solid #f4f5fa;
  overflow: auto;
}
.sidebar-content .ant-menu.ant-menu-inline-collapsed {
  height: calc(100vh - 350px);
  border-bottom: 2px solid #f4f5fa;
}
.sidebar-content .ant-menu.ant-menu-inline-collapsed .ant-menu-item,
.sidebar-content .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title {
  padding-left: calc(50% - 25px) !important;
}
.sidebar-content .ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  padding: 10px 0px;
}
.sidebar-content li.ant-menu-submenu.ant-menu-submenu-inline {
  padding-top: 1px;
}
.sidebar-content .ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: 0px;
}
.sidebar-content ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  border: 0px;
}
@media only screen and (max-width: 767px) {
  .sidebar-content ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    height: calc(100dvh - 170px);
  }
}
.sidebar-bottom-content {
  padding: 20px 0px;
  border-top: 1px solid #e8e8e8;
  font-size: 14px;
  max-height: 80px;
}
.sidebar-bottom-content .ant-menu-item .ant-menu-item-icon {
  background: #ffffff;
  margin-top: 5px;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(83, 108, 167, 0.16);
  display: flex;
  justify-content: center;
}
.sidebar-bottom-content .ant-menu-inline-collapsed > .ant-menu-item {
  padding-inline: 20px;
}
.sidebar-bottom-content li.ant-menu-item {
  margin-top: 10px;
}
.sidebar-bottom-content .user-name {
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.sidebar-bottom-content .user-name:hover {
  text-decoration: underline;
}
.sidebar-bottom-content .logout-btn:hover {
  text-decoration: underline;
}
/*sidebar css end here*/
.portal-header {
  text-transform: capitalize;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 920px) {
  .portal-header {
    max-width: 200px;
  }
}
@media only screen and (max-width: 540px) {
  .portal-header {
    max-width: 75vw;
  }
}
@media screen and (min-width: 921px) and (max-width: 1024px) {
  .portal-header {
    max-width: 430px;
  }
}
.common-button {
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.17);
  border: 1px solid #ffffff;
}
.common-button:hover {
  border-color: #ffffff !important;
}
.common-button .anticon {
  font-size: 12px;
}
.table-common-btn {
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  margin-right: 8px;
  box-shadow: none;
}
#header-title-content {
  display: flex;
}
@media only screen and (max-width: 540px) {
  #header-right-content {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .back-button-wrapper {
    display: none;
  }
}
#header-right-content-phones {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
@media only screen and (min-width: 541px) {
  #header-right-content-phones {
    display: none;
  }
}
.menu-collapsed-unfold {
  height: 64px;
}
.card-container .card-title {
  margin-left: 6px;
}
.card-container .ant-tabs-tab {
  color: #9264a8;
  height: 28px;
  font-weight: 500;
  background: #f4f5fa !important;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.card-container .ant-tabs-nav-list {
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.card-container .ant-tabs-card .ant-tabs-content {
  height: calc(100vh - 114px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  background-color: #ffffff;
  overflow-y: auto;
  margin-top: -16px;
}
@media only screen and (max-width: 600px) {
  .card-container .ant-tabs-card .ant-tabs-content {
    height: calc(100vh - 160px);
  }
}
.card-container .ant-form-item-explain {
  margin: 3px 0 9px 0px;
}
.card-container .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 24px;
  background: #ffffff;
}
.card-container .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container .ant-tabs-card .ant-tabs-tab-active,
.card-container [data-theme='compact'] .ant-tabs-card .ant-tabs-tab-active {
  background: #ffffff !important;
  border-color: #ffffff;
}
.card-container [data-theme='compact'] .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
.nested-tabs .ant-tabs-tab {
  color: #9264a8;
  height: 40px;
  font-weight: 500;
  background: none !important;
}
.nested-tabs .ant-tabs-nav-list {
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.nested-tabs .ant-tabs-content.ant-tabs-content-top {
  height: calc(100vh - 210px);
  border-radius: 0px;
  box-shadow: none;
  background-color: #ffffff;
  font-size: 12px;
  overflow-y: auto;
  margin-top: -16px;
}
@media only screen and (max-width: 600px) {
  .nested-tabs .ant-tabs-content.ant-tabs-content-top {
    height: calc(100vh - 256px);
  }
}
.nested-tabs .ant-form-item-explain {
  margin: 3px 0 9px 0px;
}
.nested-tabs .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 24px;
  background: #ffffff;
}
.nested-tabs .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.nested-tabs .ant-tabs-card .ant-tabs-tab-active,
.nested-tabs [data-theme='compact'] .ant-tabs-card .ant-tabs-tab-active {
  background: #ffffff !important;
  border-color: #ffffff;
}
.nested-tabs [data-theme='compact'] .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
.ant-pagination .ant-pagination-item-active {
  background-color: #9264a8;
}
.ant-pagination .ant-pagination-item-active a {
  color: #ffffff;
}
.ant-pagination .ant-pagination-item-active a:hover {
  color: #ffffff;
}
.search-box {
  width: 20%;
  min-width: 150px;
  height: 32px;
}
.ant-card .ant-card-head {
  min-height: 46px;
}
.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.max-width-column {
  max-width: 400px;
}
.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-btn-primary:disabled,
.ant-btn-default:disabled {
  color: grey;
  background-color: #ffffff;
}
.ant-modal .ant-modal-header {
  margin-bottom: 24px;
}
.svg-icon {
  fill: #9264a8;
  height: 16px;
}
.svg-icon-grey {
  fill: grey;
  height: 16px;
}
.text-grey {
  color: grey;
}
.custom-filter-dropdown {
  padding: 12px;
  width: 254px;
  word-wrap: break-word;
}
.custom-filter-dropdown .range-input {
  margin-top: 10px;
}
.custom-filter-dropdown .filter-search {
  width: 100%;
  margin-bottom: 12px;
}
.custom-filter-dropdown .divider-filter {
  margin: 10px 0px;
}
.custom-filter-dropdown .ok-button {
  width: 69px;
}
.custom-filter-dropdown .reset-button {
  width: 69px;
  margin-right: 9px;
  border-radius: 6px;
  background-color: #f1f3f7;
  border: none;
}
.custom-filter-dropdown .filter-checkboxes {
  margin-top: 10px;
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div {
  margin-bottom: 9px;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div .single-checkbox span {
  display: flex;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div .single-checkbox span span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div .single-checkbox {
  height: 32px;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div .single-checkbox .ant-checkbox + span {
  padding: 0 9px;
  align-self: center;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div .single-checkbox .ant-checkbox-inner::after {
  left: 27%;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div .single-checkbox .ant-checkbox-inner {
  height: 24px;
  width: 24px;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div .single-checkbox .check-info {
  margin-left: 6px;
  vertical-align: bottom;
}
.custom-filter-dropdown .selected-divider {
  font-size: 13px;
  margin: 0px;
}
.custom-filter-dropdown .filter-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.custom-filter-dropdown .filter-section .filter-tag {
  margin: 4px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}
.custom-filter-dropdown .filter-section .filter-tag span:first-child {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-filter-dropdown .ant-form-item-explain.ant-form-item-explain-error {
  font-size: 10px;
  margin: 3px 0 9px 9px;
}
.custom-filter-dropdown .ant-radio-wrapper {
  margin: 5px 0px;
}
.custom-radio-filter-dropdown-large-width {
  padding: 12px;
  width: 474px;
  word-wrap: break-word;
}
.custom-radio-filter-dropdown-large-width .divider-filter {
  margin: 10px 0px;
}
.custom-radio-filter-dropdown-large-width .ok-button {
  width: 69px;
}
.custom-radio-filter-dropdown-large-width .reset-button {
  width: 69px;
  margin-right: 9px;
  border-radius: 6px;
  background-color: #f1f3f7;
  border: none;
}
.custom-radio-filter-dropdown-large-width .selected-divider {
  font-size: 13px;
  margin: 0px;
}
.custom-radio-filter-dropdown-large-width .ant-radio-wrapper {
  margin: 5px 0px;
}
.filter-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.filter-tags-wrapper .filter-tag {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px 5px 0px 0px;
}
.filter-tags-wrapper .filter-tag span:first-child {
  display: block;
  text-overflow: ellipsis;
  width: auto;
  max-width: 180px;
  overflow: hidden;
}
.font-size-16 {
  font-size: 16px;
}
.table-action-primary-btn {
  font-size: 12px !important;
}
.ant-input-clear-icon {
  display: flex;
}
.common-view-form .ant-select-selector {
  color: #595959 !important;
  background-color: #ffffff !important;
}
.common-view-form .ant-input[disabled] {
  color: #595959;
}
.common-view-form .ant-input-affix-wrapper {
  background-color: #ffffff;
}
.common-view-form .ant-radio-wrapper-disabled {
  color: #595959;
}
.common-view-form .ant-picker.ant-picker-disabled {
  background-color: #ffffff;
}
.common-view-form .ant-picker.ant-picker-disabled .ant-picker-input > input[disabled] {
  color: #595959;
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper {
  width: auto;
}
.ant-image-img {
  border-radius: 50%;
}
.d-none {
  display: none;
}
.text-capitalize {
  text-transform: capitalize;
}
.preview-modal .ant-modal.modal .ant-modal-body {
  max-height: 800px;
}
.preview-modal .center-image {
  display: flex;
  justify-content: center;
}
.preview-modal .center-image .preview-image {
  width: 100%;
  max-width: 550px;
}
.ant-picker-input input {
  font-family: 'Baloo 2', sans-serif;
}
.action-button {
  display: flex;
  align-items: center;
}
.ant-tooltip {
  --antd-arrow-background-color: #f4f5fa;
}
.ant-tooltip .ant-tooltip-inner {
  background: #f4f5fa;
  color: #000000;
  box-shadow: none;
}
.ant-modal-footer {
  padding-top: 20px;
}
.table-data-with-id {
  overflow: hidden;
  text-overflow: ellipsis;
}
.font-size-12 {
  font-size: 12px;
}
.table-action-button .ant-btn-link:disabled,
.table-action-button {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.ant-table .ant-table-tbody > tr > td {
  padding: 0px 16px;
  height: 55px;
}
.table-cell:hover {
  color: indigo;
}
.table-tooltip-cell:hover {
  color: #6a3961;
}
.cursor-auto {
  cursor: auto;
}
.mobile-svg-icon {
  fill: #9264a8;
  height: 18px;
}
.picker-with-scroll .ant-picker-panel-container {
  overflow: scroll;
  max-width: 100vw;
}
.no-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.no-scroll-bar::-webkit-scrollbar {
  display: none;
}
.mobile-with-search-container {
  height: calc(100vh - 85px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.mobile-with-search-container .ant-card-body {
  height: calc(100vh - 85px);
}
.mobile-with-search-container .ant-table-body {
  max-height: none !important;
  height: calc(100vh - 300px);
}
.mobile-with-search-container .ant-table-placeholder {
  height: calc(100vh - 310px);
}
@media only screen and (max-width: 600px) {
  .mobile-with-search-container {
    height: calc(100vh - 100px);
  }
  .mobile-with-search-container .ant-card-body {
    height: calc(100vh - 125px);
  }
  .mobile-with-search-container .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 351px);
  }
  .mobile-with-search-container .ant-table-placeholder {
    height: calc(100vh - 357px);
  }
}
.mobile-with-search-action-container {
  height: calc(100vh - 85px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.mobile-with-search-action-container .ant-card-body {
  height: calc(100vh - 85px);
}
.mobile-with-search-action-container .ant-table-body {
  max-height: none !important;
  height: calc(100vh - 300px);
}
.mobile-with-search-action-container .ant-table-placeholder {
  height: calc(100vh - 310px);
}
@media only screen and (max-width: 600px) {
  .mobile-with-search-action-container {
    height: calc(100vh - 130px);
  }
  .mobile-with-search-action-container .ant-card-body {
    height: calc(100vh - 125px);
  }
  .mobile-with-search-action-container .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 351px);
  }
  .mobile-with-search-action-container .ant-table-placeholder {
    height: calc(100vh - 357px);
  }
}
.mobile-card-container-with-search .card-title {
  margin-left: 6px;
}
.mobile-card-container-with-search .ant-tabs-tab {
  color: #9264a8;
  height: 28px;
  font-weight: 500;
  background: #f4f5fa !important;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.mobile-card-container-with-search .ant-tabs-nav-list {
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.mobile-card-container-with-search .ant-tabs-card .ant-tabs-content {
  height: calc(100vh - 114px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  background-color: #ffffff;
  margin-top: -16px;
}
@media only screen and (max-width: 600px) {
  .mobile-card-container-with-search .ant-tabs-card .ant-tabs-content {
    height: calc(100vh - 160px);
  }
}
.mobile-card-container-with-search .ant-form-item-explain {
  margin: 3px 0 9px 0px;
}
.mobile-card-container-with-search .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 24px;
  background: #ffffff;
  height: calc(100vh - 300px);
}
.mobile-card-container-with-search .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.mobile-card-container-with-search .ant-tabs-card .ant-tabs-tab-active,
.mobile-card-container-with-search [data-theme='compact'] .ant-tabs-card .ant-tabs-tab-active {
  background: #ffffff !important;
  border-color: #ffffff;
}
.mobile-card-container-with-search [data-theme='compact'] .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
.mobile-card-container .card-title {
  margin-left: 6px;
}
.mobile-card-container .ant-tabs-tab {
  color: #9264a8;
  height: 28px;
  font-weight: 500;
  background: #f4f5fa !important;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.mobile-card-container .ant-tabs-nav-list {
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.mobile-card-container .ant-tabs-card .ant-tabs-content {
  height: calc(100vh - 114px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  background-color: #ffffff;
  margin-top: -16px;
}
@media only screen and (max-width: 600px) {
  .mobile-card-container .ant-tabs-card .ant-tabs-content {
    height: calc(100vh - 130px);
  }
}
.mobile-card-container .ant-form-item-explain {
  margin: 3px 0 9px 0px;
}
.mobile-card-container .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 24px;
  background: #ffffff;
  height: calc(100vh - 130px);
}
.mobile-card-container .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.mobile-card-container .ant-tabs-card .ant-tabs-tab-active,
.mobile-card-container [data-theme='compact'] .ant-tabs-card .ant-tabs-tab-active {
  background: #ffffff !important;
  border-color: #ffffff;
}
.mobile-card-container [data-theme='compact'] .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
.mobile-with-search-actions {
  height: calc(100vh - 85px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.mobile-with-search-actions .ant-card-body {
  height: calc(100vh - 85px);
}
.mobile-with-search-actions .ant-table-body {
  max-height: none !important;
  height: calc(100vh - 300px);
}
.mobile-with-search-actions .ant-table-placeholder {
  height: calc(100vh - 310px);
}
@media only screen and (max-width: 600px) {
  .mobile-with-search-actions {
    height: calc(100vh - 100px);
  }
  .mobile-with-search-actions .ant-card-body {
    height: calc(100vh - 100px);
  }
  .mobile-with-search-actions .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 351px);
  }
  .mobile-with-search-actions .ant-table-placeholder {
    height: calc(100vh - 357px);
  }
}
.mobile-with-search-nav-actions {
  height: calc(100vh - 85px);
  border-radius: 0px;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.mobile-with-search-nav-actions .ant-card-body {
  height: calc(100vh - 85px);
}
.mobile-with-search-nav-actions .ant-table-body {
  max-height: none !important;
  height: calc(100vh - 370px);
}
.mobile-with-search-nav-actions .ant-table-placeholder {
  height: calc(100vh - 310px);
}
@media only screen and (max-width: 600px) {
  .mobile-with-search-nav-actions {
    height: calc(100vh - 130px);
  }
  .mobile-with-search-nav-actions .ant-card-body {
    height: calc(100vh - 100px);
  }
  .mobile-with-search-nav-actions .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 351px);
  }
  .mobile-with-search-nav-actions .ant-table-placeholder {
    height: calc(100vh - 357px);
  }
}
.mobile-container-search-action {
  height: calc(100vh - 230px);
  overflow: scroll;
}
.mobile-container-search-action .ant-card {
  border: 0px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.08);
}
.mobile-container-search-action .ant-card-body {
  height: auto !important;
}
.mobile-container-transfer {
  height: calc(100vh - 300px);
  overflow: scroll;
}
.mobile-container-transfer .ant-card {
  border: 0px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.08);
}
.mobile-container-transfer .ant-card-body {
  height: auto !important;
}
.mobile-container {
  height: calc(100vh - 200px);
  overflow: scroll;
}
.mobile-container .ant-card {
  border: 0px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.08);
}
.mobile-container .ant-card-body {
  height: auto !important;
}
.mobile-full-screen-container {
  height: calc(100vh - 120px);
  overflow: scroll;
}
.mobile-full-screen-container .ant-card {
  border: 0px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.08);
}
.mobile-container-tab {
  height: calc(100vh - 260px);
  overflow: scroll;
}
.mobile-container-tab .ant-card {
  border: 0px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.08);
}
.mobile-container-tab .ant-card-body {
  height: auto !important;
}
.mobile-container-action {
  height: calc(100vh - 250px);
  overflow: scroll;
}
.mobile-container-action .ant-card {
  border: 0px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.08);
}
.mobile-container-action .ant-card-body {
  height: auto !important;
}
.mobile-container-nav-action {
  height: calc(100vh - 345px);
  overflow: scroll;
}
.mobile-container-nav-action .ant-card {
  border: 0px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.08);
}
.mobile-container-nav-action .ant-card-body {
  height: auto !important;
}
.mobile-card-label {
  color: #626262;
  font-weight: 600;
}
.full-screen-modal .ant-modal,
.full-screen-modal .ant-modal-content {
  height: 100vh !important;
  width: 100% !important;
  max-width: 100% !important;
  border-radius: 0px;
}
.full-screen-modal .ant-modal-body {
  margin-top: 30px;
}
.card-with-shadow {
  border: 0px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.08);
}
.dashed-line {
  border-bottom: 1px dashed #e4e6ea;
  height: 0;
  margin: 20px 0;
}
.mobile-card-main .ant-card-body,
.mobile-card-main .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 15px !important;
}
.mobile-card .ant-card-body,
.mobile-card .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 24px !important;
}
.overflow-scroll {
  display: flex;
  overflow-x: scroll;
}
.only-card-container {
  border-radius: 0%;
  height: calc(100vh - 100px);
  overflow: auto;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.only-card-container .ant-card {
  border-radius: 0px;
  border: 0px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.08);
}
.font-weight-bold {
  font-weight: bold;
}
.ql-editor {
  height: 150px;
}
.info-card-container .ant-statistic-title {
  margin-right: 30px;
}
.info-card-container .statistic-custom-title {
  color: indigo;
}
.info-card-container .statistic-custom-title:hover {
  text-decoration: underline;
}
.ant-table-wrapper .ant-table-cell-ellipsis {
  overflow: unset !important;
}
/*breakpoint mixin start here*/
/*breakpoint mixin end here*/
/*typography css end here*/
/**https://github.com/ant-design/ant-design/blob/master/components/style/themes/variable.less**/
